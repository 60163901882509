import { defineStore } from 'pinia';
import vendorService from '@/service/vendor.service';
import requestVendorRatingsService from '@/service/request-vendor-ratings.service';
import { getAllPaginatedItems } from '@/utils';
import requestsService from '@/service/requests.service';

export const useVendorStore = defineStore('vendor', {
  state: () => ({
    vendorRatingsByQuery: null,
    vendor: null,
    vendorIncidentHistory: null,
    allVendorsData: [],
  }),
  actions: {
    async getVendorRatingsByQuery(query) {
      const allRatings = await getAllPaginatedItems(
        requestVendorRatingsService.getRequestVendorRatingsByQuery,
        {
          ...query,
          relations: 'createdByUser',
          orderBy: 'createdAt',
          orderDirection: 'ASC',
        },
      );
      if (!query.serviceCategoryId) {
        this.vendorRatingsByQuery = allRatings;
      }
      return allRatings;
    },
    async setVendorById({ vendorId, query }) {
      const results = await vendorService.getVendorById(vendorId, query);
      this.vendor = results;
    },
    async getVendorById(vendorId) {
      const results = await vendorService.getVendorById(vendorId);
      return results;
    },
    async getVendorByIdWithRelations({ vendorId, query }) {
      const results = await vendorService.getVendorById(vendorId, query);
      return results;
    },
    async getVendorIncidentHistory(vendorId) {
      const dispatchRequested =
        await requestsService.getOpenDispatchRequestsByVendor(vendorId);
      const allRequests = await getAllPaginatedItems(
        requestsService.getRequestsByQuery,
        { assignedToVendorId: vendorId, relations: 'location' },
      );
      const results = {
        dispatchRequested,
        openRequests: allRequests.filter((request) => !request.completedAt),
        closedRequests: allRequests.filter((request) => request.completedAt),
      };
      this.vendorIncidentHistory = results;
    },
    async patchVendor(patchBody) {
      const curatedPatchBody = { ...patchBody };
      // if users and userIds are both present, we only want to send userIds so we don't cause a conflict.
      if (
        Array.isArray(curatedPatchBody.users) &&
        Array.isArray(curatedPatchBody.userIds)
      ) {
        delete curatedPatchBody.users;
      }

      await vendorService.updateVendor(this.vendor.id, curatedPatchBody);
    },
    async getAllVendors(queryParams) {
      const response = await vendorService.getVendorsByQuery({
        ...queryParams,
        relations: ['vendorAutomationOptions', 'users'],
      });
      this.allVendorsData = response;
    },
    async addToVendors(queryParams) {
      const response = await vendorService.getVendorsByQuery(queryParams);
      this.allVendorsData.data.push(...response.data);
    },
    clearVendorList() {
      this.allVendorsData.data = [];
    },
    async checkVendorForDupes(vendorInfo) {
      const response = await vendorService.checkVendorForDupes({
        name: vendorInfo.name,
        email: vendorInfo.email,
        phone: vendorInfo.phone,
      });
      return response;
    },
    async saveNewVendor(newVendor) {
      const response = await vendorService.saveNewVendor(newVendor);
      return response;
    },
  },
  getters: {
    getVendorCopy: (state) => ({ ...state.vendor }),

    // request is here in case you're looking at the vendor and the request at the same time
    getVendorAutoDispatchAbilities: () => (vendor) => {
      const hasAEmailableUser = vendor?.users?.some((u) => u.email);

      const canFSOAutoDispatch =
        vendor?.vendorAutomationOptions?.usesAutoDispatchConfirmation &&
        hasAEmailableUser;

      const canSSOAutoDispatch =
        vendor?.vendorAutomationOptions?.usesAutoDispatchConfirmation &&
        vendor?.vendorAutomationOptions?.autoVendorFollowUpType ===
          'Single Page' &&
        hasAEmailableUser;

      const canInternalTechAutoDispatch =
        vendor?.vendorAutomationOptions?.internalTechAutoDispatch;

      return {
        canFSOAutoDispatch,
        canSSOAutoDispatch,
        canInternalTechAutoDispatch,
      };
    },
  },
});
